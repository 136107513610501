@import '~antd/dist/antd.less';

html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}
body {
  margin: 0;
  font-family: "Microsoft Yahei";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* 列表相关 */
.table-list {
  .list-action .ant-btn {
      padding: 4px;
  }
  .operator {
    margin-bottom: 16px;
    button {
      margin-right: 8px;
    }
  }
}
.table-list-form {
  .ant-row {
      width: 100%;
  }
  .ant-form-item {
      margin-bottom: 24px;
      margin-right: 0;
      display: flex;
      > .ant-form-item-label {
          width: auto;
          line-height: 32px;
          padding-right: 8px;
      }
      .ant-form-item-control {
          line-height: 32px;
      }
  }
  .ant-form-item-control-wrapper {
      flex: 1;
  }
  .submit-btn {
      white-space: nowrap;
      margin-bottom: 24px;
  }
}
@primary-color: #931937;@link-color: #931937;